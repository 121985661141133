import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useRecordContext, Loading, CardActions} from "react-admin";
import {useGetOne} from 'react-admin';
import {Box, Button, Card, CardContent, Grid, LinearProgress, Link, Typography} from "@material-ui/core";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function createData(name, calories, fat, carbs, protein) {
    return {name, calories, fat, carbs, protein};
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: 'lightgreen',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#dd7e6b',
    },
}))(LinearProgress);

const CustomLinearPropensity = (props) => {

    return (
        <Grid container
              justifyContent="center"
              alignItems="center"
              spacing={1}>

            <Grid item xs={4}>
                <Typography
                    style={{
                        color: "black",
                    }}
                >
                    {Math.round(props.value)}%
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <BorderLinearProgress
                    variant="determinate"
                    {...props}
                />
            </Grid>

        </Grid>
    )
}

const PathologyTable = (props) => {
    console.log(props);
    const classes = useStyles();

    let rows = []
    let first = false;
    for (const [key, value] of Object.entries(props.data)) {
        console.log(`${key}: ${value}`);
        first = true
        let length = Object.entries(value).length
        for (const [pathology_key, pathology_value] of Object.entries(value)) {
            rows.push({
                'feature_key': key,
                'feature': pathology_value['pathology']['feature']['value'],
                'pathology': pathology_value['pathology']['value'],
                'score': pathology_value['score'],
                'first': first,
                'colspan': length
            })
            first = false
        }


    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Feature</TableCell>
                        <TableCell>Pathology</TableCell>
                        <TableCell>Prediction</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        let propensity_table_cell = null;
                        if (row.score !== null) {
                            propensity_table_cell = <TableCell align="right">
                                <div>
                                    <CustomLinearPropensity variant="determinate" value={row.score * 100}/>
                                </div>


                            </TableCell>
                        } else {
                            propensity_table_cell = <TableCell align="right"> </TableCell>
                        }

                        return (<TableRow key={row.pathology}>
                            <TableCell component="th" scope="row">
                                {row.feature}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.pathology}
                            </TableCell>
                            {propensity_table_cell}
                        </TableRow>)
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


const MeasurementsTable = (props) => {
    console.log(props);
    const classes = useStyles();

    let rows = []

    for (const [key, value] of Object.entries(props.data)) {
        rows.push({
            'measurement_key': key,
            'measurement_name': value['measurement']['value'],
            'value': value['value'],
            'measurement': value['measurement'],
            'units': value['measurement']['units']
        })
    }

    const formatValue = (row) =>{
        if(row.value !== null){
            return (Math.round(row.value * 100) / 100).toString() + " " + row.units
        }
        return null

    }

    const formatReference = (row) =>{
        if(row.measurement.low_range != null) {
            return row.measurement.low_range + " - " +  row.measurement.high_range + " "  + row.units
        }
        return null
    }

    return (
        <TableContainer style={{marginTop: '50px'}} component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Measurement</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Reference</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.measurement_key}>
                            <TableCell component="th" scope="row">
                                {row.measurement_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {formatValue(row)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {formatReference(row)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}




const InferenceOverviewCard = (props) => {
    const useStyles_overview = makeStyles({
        root: {
            minWidth: 275,
            marginBottom: 12
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });

    const classes = useStyles_overview();
    const bull = <span className={classes.bullet}>•</span>;
    let created_at = null;

    try{
        var today  = new Date(props.data.created_at);
        console.log(today)
        created_at = today.toLocaleString();

    }catch (e){
    }

    let download_action = null;
    if(props.data.file)
    {
        const onDownload = () => {
            const link = document.createElement("a");
            link.download = props.data.study.name + ".pdf";
            link.href = props.data.file;
            link.target = '_blank'
            link.click();
        };

        download_action = (
            <CardActions>
                <Button onClick={onDownload} size="small">Download as pdf</Button>
            </CardActions>
        )
    }


    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Study: {props.data.study.name}
                </Typography>

                <Typography className={classes.title} color="textSecondary">
                    Created at: {created_at}
                </Typography>

            </CardContent>
            {download_action}
        </Card>
    );
}

export const ReportShow = (props) => {
    const report_id = props.id

    const [pathologyData, setPathologyData] = useState(null);
    const [measurementData, setMeasurementData] = useState(null);
    const [inferenceData, setInferenceData] = useState(null);


    const {data, loading, loaded, error} = useGetOne('reports', report_id, {
        onSuccess: (data) => {
            setPathologyData(data.data['json_report']['pathologies'])
            setMeasurementData(data.data['json_report']['measurements'])
            setInferenceData(data.data)
        }
    });
    if (loading) {
        return <Loading/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    let pathologyTable = null
    let measurementTable = null
    if (pathologyData) {
        pathologyTable = (<PathologyTable data={pathologyData}/>);
    }

    if (measurementData) {
        measurementTable = (<MeasurementsTable data={measurementData}/>)
    }

    if (inferenceData) {
        return (
            <div style={{maxWidth: '800px'}}>
                <InferenceOverviewCard data={inferenceData}/>
                {pathologyTable}
                {measurementTable}
            </div>
        )
    }

    return (<Loading/>);
};