import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    useRecordContext,
    Datagrid,
    TextField,
    DateField,
    List,
    Pagination,
    ArrayField,
    useDelete,
    EditButton,
    required, ShowButton, SelectInput, ReferenceInput, useNotify, useRefresh, useCreate
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {Button, Grid, Link, Typography} from "@material-ui/core";



import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


const Delete = (props) =>{
    const notify = useNotify();
    const record = useRecordContext(props);
    const reload = useRefresh()

    const [create, { data, loading, error }] = useDelete('dicoms',record.id, {},{
        onFailure: (error) => {
            notify(error.message, 'error')
        },
        onSuccess: (data) => {
            notify('Dicom deleted!', 'success')
            reload()
        },
    });
    const handleClick = () => {
        create()
    }

    return <Button
        disabled={loading}
        onClick={handleClick}>Delete</Button>;
}

const MyUploader = (props) => {
    // specify upload params and url for your files
    console.log(props);
    const refresh = useRefresh();

    const getUploadParams = ({ file, meta }) => {
        const body = new FormData()
        body.append('file', file)
        body.append('study', props.id)
        return  { url: process.env.REACT_APP_API_URL + '/api/v2/dicoms/',
            headers: {'Authorization': 'Bearer ' + window.localStorage.getItem('access')
            },
            body: body
        }
    }


    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {
        if(status == 'done'){
            refresh()
    } }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            inputContent={'Add new DICOMs by dragging or click to browse'}
            canRemove={false}
            accept=".dcm"
        />
    )
}

export const StudyEdit = (props) => (

    <Edit {...props}>

        <SimpleForm>
            <TextInput disabled label="ID" source="id"/>
            <TextInput source={'name'}/>
            <DateField showTime={true} source="created_at" />


            <ReferenceManyField pagination={<Pagination />} reference="dicoms"  target="study">
                <Datagrid>
                    <TextField source="name" />
                    <DateField showTime={true} source="created_at" />
                    <Delete />
                </Datagrid>
            </ReferenceManyField>

            <MyUploader {...props}  />

        </SimpleForm>


    </Edit>
);