import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    useRecordContext,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required, ShowButton, SelectInput, ReferenceInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {Button, Grid, Link} from "@material-ui/core";


export const StudyCreate = (props) => (

    <Create {...props}>


        <SimpleForm>
            <TextInput source={'name'}/>

        </SimpleForm>


    </Create>
);