import BookIcon from "@material-ui/icons/Book";
import {StudyList} from "./StudyList";
import {StudyCreate} from "./StudyCreate";
import {StudyEdit} from "./StudyEdit";
import {StudyShow} from "./StudyShow";


export default {
    list: StudyList,
    create: StudyCreate,
    edit: StudyEdit,
    show: StudyShow,
    icon: BookIcon,
}