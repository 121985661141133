/* eslint react/jsx-key: off */
import * as React from 'react';
import { Admin, Resource, RouteWithoutLayout, } from 'react-admin';
import { render } from 'react-dom';

import i18nProvider from './i18nProvider';
import Layout from './Layout';

import study from './study';
import report from './report';
import token from './token';
import webhook from './webhook';
import { parseBool } from "./helpers";

import { Route } from 'react-router-dom';

import drfProvider, {
    fetchJsonWithAuthJWTToken,
    fetchJsonWithAuthToken,
    jwtTokenAuthProvider, tokenAuthProvider
} from "ra-data-django-rest-framework";
const useJWTAuth = parseBool(process.env.REACT_APP_USE_JWT_AUTH);
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

console.log(REACT_APP_API_URL);
let authProvider;
let dataProvider;
let encephalonProvider;



dataProvider = drfProvider( REACT_APP_API_URL + '/api/v2' , fetchJsonWithAuthJWTToken);

encephalonProvider = new Proxy(dataProvider, {
    get: (target, name) => (resource, params) => {
        // add rejection by type or resource here for tests, e.g.
        // if (name === 'delete' && resource === 'posts') {
        //     return Promise.reject(new Error('deletion error'));
        if (name === 'getOne' || name ==='update' || name ==='create'){
            return target[name](resource, params).then(
                ({ data }) => ({
                    data: {id: data.uuid,
                    ...data
                    },
                })
            );
        }

        if (name === 'getList' || name==='getManyReference'){
            return target[name](resource, params).then(
                ({ data, total }) => ({
                    data: data.map(resource => ({ ...resource, id: resource.uuid }) ),
                    total:total
                })
            );

        }

        return target[name](resource, params)
    },
});

authProvider = jwtTokenAuthProvider({obtainAuthTokenUrl: REACT_APP_API_URL + '/api/token/'});



render(
    <React.StrictMode>
        <Admin
            authProvider={authProvider}
            dataProvider={encephalonProvider}
            i18nProvider={i18nProvider}
            title="Example Admin"
            layout={Layout}
            customRoutes={[
            ]}
        >
            {permissions => [
                /*<Resource name="posts" {...posts} />,
                <Resource name="comments" {...comments} />,
                permissions ? <Resource name="users" {...users} /> : null,
                <Resource name="tags" {...tags} />,*/

                <Resource name="studies" {...study} />,
                <Resource name="dicoms" />,
                <Resource name="scans" />,
                <Resource name="reports" {...report} />,
                <Resource options={{label: 'Tokens'}} name="account/user/tokens" {...token} />,
                <Resource options={{label: 'Webhooks'}} name="webhook" {...webhook} />,

               /* <Resource name="datasource" list={ListGuesser} />*/
            ]}
        </Admin>
    </React.StrictMode>,
    document.getElementById('root')
);
