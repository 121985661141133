import {Datagrid, DateField, EditButton, List, ShowButton, TextField} from "react-admin";
import * as React from "react";


export const TokenList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="key" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);