import {Datagrid, DateField, EditButton, List, ShowButton, TextField} from "react-admin";
import * as React from "react";


export const StudyList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <DateField showTime={true} source="created_at" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);