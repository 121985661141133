import * as React from "react";
import {
    Create,
    Edit,
    Show,
    SimpleForm,
    TextInput,
    DateInput,
    List,
    ReferenceManyField,
    useRecordContext,
    Datagrid,
    TextField,
    useRefresh,
    DateField,
    useNotify,
    TabbedForm,
    FormTab,
    useRedirect,
    useCreate,
    EditButton,
    required, ShowButton, RichTextField, ArrayField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { Button } from "react-admin";
import {Link} from "@material-ui/core";
import { Pagination } from 'react-admin';

const OpenReport = (props)  => {
    const record = useRecordContext(props);
    if(record.report !== null && record.report.file !== null)
    {
        return (
            <Button
                onClick={ ()=>{
                    window.open(record.report.file, '_blank')
                } }
                label="Download PDF report"
            />)
    }
    return null;
};

const StartInference = (props) =>{
    const notify = useNotify();
    const record = useRecordContext(props);
    const reload = useRefresh()

    const [create, { data, loading, error }] = useCreate('scans', { study: record.id},{
        onFailure: (error) => {
            notify(error.message, 'error')
        },
        onSuccess: (data) => {
            notify('Inference requested!', 'success')
            reload()
        },
    });
    const handleClick = () => {
        create()
    }

    return <Button
        variant="outlined"
        disabled={loading}
        label={"Infer study"}
        onClick={handleClick}
    />;
}

const ViewReportButton = ({ record, ...props }) => {
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('show','/reports',record.report);
    }

    if(record.status == 'COMPLETED')
    {
        return (
            <Button
                onClick={handleClick}
                label="View report"
            />)
    }
    return null;

};

const DurationField = ({ source }) => {

    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const record = useRecordContext();
    return (<span >{record && record[source] && secondsToHms(record[source])}</span>);
};

export const StudyShow = (props) => (

    <Show {...props}>

        <TabbedForm warnWhenUnsavedChanges>

            <FormTab label="Study overview">
                    <TextInput disabled label="ID" source="id"/>
                    <TextField source={'name'}/>
                    <DateField showTime={true} source="created_at" />

                    <ReferenceManyField pagination={<Pagination />} reference="dicoms"  target="study">
                        <Datagrid>
                            <TextField source="name" />
                            <DateField showTime={true} source="created_at" />

                        </Datagrid>
                    </ReferenceManyField>

            </FormTab>

            <FormTab label="Scans">


                    <ReferenceManyField pagination={<Pagination />} reference="scans" target="study">
                        <Datagrid>
                            <TextField source="uuid" />
                            <DateField showTime={true} source="created_at" />
                            <DateField showTime={true} source="start_inference_at" />
                            <DateField showTime={true} source="end_inference_at" />
                            <DurationField source="total_inference_time" />
                            <TextField source="status" />
                            <TextField source="number_of_available_dicoms" />
                            <TextField source="number_of_dicoms_scanned" />

                            <ViewReportButton {...props} />

                        </Datagrid>

                    </ReferenceManyField>

                    <StartInference />
            </FormTab>



        </TabbedForm>




    </Show>
);